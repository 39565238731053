import React, { useContext, useEffect, useState } from "react";
import { FindDealerContext } from "./context/findDealerContext";
import view from "./findDealer.module.scss";
import MapMarkerIcon from "./assets/mapMarkerIcon";
import sellVehicleMobile from "../../images/sell-your-vehicle-mobile.png"
import sellVehicleDesktop from "../../images/sell-your-vehicle-desktop.png"

const { siteMetadata } = require("../../../gatsby-config");
const googleAPIkey = siteMetadata.googleAPIkey;

const FindDealerMobileHome = (props) => {
  const [formZip, setFormZip] = useState("");

  //Define context
  const findDealerProps = useContext(FindDealerContext);

  /**
   * Define values from findDealerContext
   *
   * @param {method} searchZipCode calls three other functions from context setZipcode, isThereHistory, distanceRef
   * and passes the zipcode searched.
   */

  const { searchZipCode, scrollToListElement } = findDealerProps;

  const keyPress = (e, formZip) => {
    if (e.keyCode === 13) {
      searchZipCode(formZip);
    }
  };

  function holdFormZip(val) {
    setFormZip(val);
  }

  function submission(e, formZip) {
    searchZipCode(formZip);
    e.preventDefault();
    scrollToListElement();
  }

  return (
    <div className={`${view["mobile-search"]} ${props.isSellYourVehicle === true ? view["sellVehicle-mobile"] : "other"}`}>
          <p className={view["find-a-dealer-para"]}>
            Find Your Nearest <span></span> Dealer
          </p>
      <form
        className={view["search-box-mobile"]}
        onSubmit={(e) => submission(e, formZip)}
        autoComplete="off"
      >
        <div className={view["search-input-mobile"]}>
          <MapMarkerIcon />
          <input
            maxLength="5"
            maxLength="5"
            minLength="5"
            className={view["zipcode-input"]}
            required
            name="searchedZip"
            label="zip code"
            type="text"
            placeholder="Enter Zipcode"
            wrap="hard"
            onChange={(e) => holdFormZip(e.target.value)}
            onKeyDown={(e) => keyPress(e, formZip)}
          />
        </div>
        <button
          className={view["search-button-mobile"]}
          onClick={(e) => submission(e, formZip)}
        >
          SEARCH
        </button>
      </form>

      {props.isSellYourVehicle
        ? (
          <div className={view["sell-vehicle"]}>
            <picture>
              <source srcset={sellVehicleDesktop} media="(min-width: 600px)"/>
              <img src={sellVehicleMobile} className={view["sell-vehicle__img"]} alt="steps to sell your vehicle" />
            </picture>
          </div>
        )
        : <></>}
    </div>
  );
};

export default FindDealerMobileHome;
