import React, { useEffect, useContext } from "react"
import Layout from '../components/layout'
import SEO from '../components/SEO'
import HTML from "../components/HTML"
import Jumbotron from "../components/jumbotron/Jumbotron"
import DealerArray from "../components/dealerArray/DealerArray"
import WrappedFindDealerMobile from "../components/findDealer/wrappedFindDealerMobile"


export default function DealerInfoTemplate(props) {
  const { pageContext } = props
  const { pageContent, dealerInfo } = pageContext

  let button1 = {
    text: pageContent.DealerCards.buttonText1,
    type: pageContent.DealerCards.buttonType1
  }
  let button2 = {
    text: pageContent.DealerCards.buttonText2,
    type: pageContent.DealerCards.buttonType2
  }

  let dealersRaw = pageContent.DealerCards.dealerList.split(' ').join('').split(',')
  let dealersFiltered = pageContext.dealers.filter(filterDealers)

  function filterDealers(dealer) {
    return dealersRaw.includes(dealer.dealerId)
  }
  let brandsRaw = pageContent.DealerCards.makeList.split(',')

  if(pageContent.DealerCards.brandList !== '') {
    dealersFiltered = dealersFiltered.filter(filterBrands)
  }
  function filterBrands(dealer) {
    let flag = false;
    brandsRaw.forEach((brand, i, arr) => {
      if(dealer.dealerName.indexOf(brand) >= 0) {
        flag = true;
      }
    })
    return flag;
  }

  const components = [
    <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} />,
    <Jumbotron key={"Jumbotron"} data={{ ...pageContent.Jumbotron }} />,
    <WrappedFindDealerMobile pageContent={pageContent} pageContext={pageContext}
      dealerInfo={dealerInfo} data={{ order: 2, visible: pageContent.findDealerBar.visible }} />,
    <DealerArray buttonOne={button1} buttonTwo={button2} dealers={dealersFiltered} data={{ order: 3, visible: true }}
    />
  ]

  if (pageContent.hasOwnProperty("ArrayRawHTML")) {
    const { arrayRawHTML } = pageContent.ArrayRawHTML;
    const rawComponents = arrayRawHTML.map((element) => {
      return (
        <HTML
          data={{
            order: Number(element.order),
            visible: element.visible,
            rawHTML: element.rawHtml,
          }}
          key={"HTML"}
        />
      );
    });
    components.push(...rawComponents);
  }

  const sortedComponents = components.sort((a, b) =>
    parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  )
  const visibleComponents = sortedComponents.filter(component => {
    return component.props.data.visible === true
  })

  return (
    <Layout>
      {visibleComponents}
    </Layout>
  )
}
