import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"

import { FindDealerContext } from "../findDealer/context/findDealerContext";

import grid from "../../scss/flexboxgrid.module.scss"
import view from "./dealers.module.scss"

export default function DealerArray(props) {
  const [dealers, setDealers] = useState([])
  const { loadingList, localStorageDistanceData, scrollToListRef, setClosestDealerCompPresent } = useContext(FindDealerContext);

 useEffect(() => {
    setDealers(props.dealers || [])
    setClosestDealerCompPresent(true);
  }, [])

  useEffect(() => {
    if (localStorageDistanceData) {
      const sortedDealers = localStorageDistanceData.closest_dealers || []
      const procDealers = sortedDealers.map(sortedDealer => {
        const dealerData = props.dealers.find(dealer => dealer.dealerName === sortedDealer.dealerName)
        return { ...sortedDealer, ...dealerData }
      })
      setDealers(procDealers)
    }
  }, [localStorageDistanceData])

  const renderButtons = (buttonInfo, dealer) => (
    <>
      {
        buttonInfo.type == 'Service' ? <Link to={dealer.servicePageURL} className={view["card-button"]}>{buttonInfo.text}</Link> :
        buttonInfo.type == 'Parts' ? <Link to={dealer.partsPageURL} className={view["card-button"]}>{buttonInfo.text}</Link> : 
        buttonInfo.type == 'Website' ? <Link to={dealer.websiteLink} className={view["card-button"]}>{buttonInfo.text}</Link> :
        buttonInfo.type == 'Offers' ? <Link to={dealer.offersPageURL} className={view["card-button"]}>{buttonInfo.text}</Link> :
        buttonInfo.type == 'Sell' ? <Link to={dealer.sellYourCarURL} className={view["card-button"]}>{buttonInfo.text}</Link> :
        buttonInfo.type == 'Phone' ? <a href={"tel:" + dealer.mainPhoneNumber} className={view["card-button"]}>{buttonInfo.text}</a> : 
        buttonInfo.type == 'Inventory' ? <Link to={dealer.inventoryURL} className={view["card-button"]}>{buttonInfo.text}</Link> : 
        <Link></Link>
      }
    </>
  )

  const renderDealer = (dealer) => (
    <div className={view["dealer-card"]}>
      <img alt={dealer.dealerName + ' Service'} className={view["card-img"]} src={dealer.dealerServiceImage} />
      <p className={view["dealer-title"]}>{dealer.dealerName}</p>
      {dealer.distances && 
        <p className={view["dealer-dist"]}>
          {`${dealer.distances.distance.text} Away`}
        </p> 
      }
      <p>{dealer.dealerAddress}</p>
      <p>{dealer.mainPhoneNumber}</p>
      <div className={view["buttons"]}>
        {props.hasOwnProperty('buttonOne') ? renderButtons(props.buttonOne, dealer) : <Link to={dealer.servicePageURL} className={view["card-button"]}>SERVICE</Link>}
        {props.hasOwnProperty('buttonTwo') ? renderButtons(props.buttonTwo, dealer) : <Link to={dealer.partsPageURL} className={view["card-button"]}>PARTS</Link>}
      </div>
    </div>
  )

  return (
    <section className={view["array-wrap"]} ref={scrollToListRef}>
      <div className={view["array-cont"]}>
        {dealers.length > 0 && dealers.map(dealer => (
          <div className={`${grid["col-xs-12"]} ${grid['col-sm-6']} 
            ${grid["col-md-4"]} ${grid["col-lg-3"]}`}>
            {renderDealer(dealer)}
          </div>
        ))}
      </div>
    </section>
  )
}

DealerArray.defaultProps = {
  dealers: []
}
